<template>
  <div>
    <LayoutSolutionHeader :bgImgString='bgImgStr'></LayoutSolutionHeader>
    <Detail1 v-show="id === '1'"></Detail1>
    <Detail2 v-show="id === '2'"></Detail2>
    <Detail3 v-show="id === '3'"></Detail3>
    <Detail4 v-show="id === '4'"></Detail4>

    <LayoutFooter></LayoutFooter>
  </div>
</template>

<script>
import bg1 from '@src/assets/img/solution-detail-1.png'
import bg2 from '@src/assets/img/solution-detail-2.png'
import bg3 from '@src/assets/img/solution-detail-3.png'
import bg4 from '@src/assets/img/solution-detail-4.png'
import LayoutSolutionHeader from '@src/components/LayoutSolutionHeader'
import LayoutFooter from '@src/components/LayoutFooter'
import Detail1 from '@src/components/solutionDetail/Detail1'
import Detail2 from '@src/components/solutionDetail/Detail2'
import Detail3 from '@src/components/solutionDetail/Detail3'
import Detail4 from '@src/components/solutionDetail/Detail4'

export default {
  components: {
    LayoutSolutionHeader,
    LayoutFooter,
    Detail1,
    Detail2,
    Detail3,
    Detail4,
  },
  name: '',
  data () {
    return {
      bgImgStr: bg1,
      id: 1,
    };
  },
  mounted () {
    window.scrollTo(0, 0)
    let query = this.$route.query
    this.id = query.id
    console.log(query.id)
    switch (query.id) {
      case '1':
        this.bgImgStr = bg1
        break
      case '2':
        this.bgImgStr = bg2
        break
      case '3':
        this.bgImgStr = bg3
        break
      case '4':
        this.bgImgStr = bg4
        break
    }
  }

}
</script>

<style scoped>
</style>
