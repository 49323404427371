<template>
  <div>
    <div class="top">
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail4-1.png">
        <div class="item">
          <div class="title">完美档案</div>
        </div>
      </div>
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail4-2.png">
        <div class="item">
          <div class="title">中医体质分析</div>
        </div>
      </div>
    </div>
    <div class="t">
      <div class="t-2">
        <div>
          <div class="title-left">
            完美档案-血压分析评估
          </div>
          <div class="text-left">
            灵活的档案构建模式，自动化推送健康问卷<br>
            提供高血压疾病风险评估，进行生活建议与指导
          </div>
          <div class="btn">查看评估演示</div>
        </div>
        <div class="img-left">
          <img src="../../assets/img/solutionDetail/detail4-3.png">
        </div>
      </div>

      <div class="t-3">
        <div class="img">
          <img src="../../assets/img/solutionDetail/detail4-4.png">
        </div>
        <div>
          <div class="title">
            中医体质评估
          </div>
          <div class="text">
            深入人体体质认知，把握健康与疾病的整体要素与个体差异的手段<br>
            中医各类型体质说明与生活建议
          </div>
          <div class="btn-right">查看评估演示</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    };
  },
}
</script>

<style lang="scss"  scoped>
.top {
  display: flex;
  justify-content: space-around;
  margin: 1.2rem 2rem;
  .topItem {
    display: flex;
    justify-content: center;
    img {
      margin: 0 0.2rem;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(89, 89, 89, 1);
      }
      .text {
        font-size: 0.18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
      }
    }
  }
}

.t {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-around;
    width: 12rem;
    flex-direction: row;
    margin-bottom: 1.17rem;
    img {
      width: 5.7rem;
    }
    .title-left {
      text-align: left;
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
    }
    .text-left {
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      text-align: left;
      margin: 0.4rem 0;
    }

    .text-c3 {
      margin: 0.25rem 0 0 0;
    }
    .c3 {
      margin: 0.3rem 0 0 0 !important;
      display: flex;
    }
    .img-right {
      margin: 0 0 0 0.85rem;
      background-size: cover;
    }
    .img-left {
      margin: 0 0.85rem 0 0;
      background-size: cover;
    }
    .title {
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      text-align: right;
    }
    .text {
      text-align: right;
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      margin: 0.4rem 0;
    }
    .btn {
      text-align: left;
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 198, 174, 1);
    }
    .btn-right {
      text-align: right;
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 198, 174, 1);
    }
  }
}
</style>
