<template>
  <div class="header">
    <img :src='bgImgString'>
    <ul class="menu">
      <li class="home"
          @click="$router.go(-1)">爱家医PHR云平台</li>
      <li v-show="showMenu"
          class="index"><a href="./">首页</a></li>
      <li v-show="showMenu"
          @click="goFloor('s2')"><a>案例</a></li>
      <li v-show="showMenu"><a :href="documentUrl"
           target="_blank">文档</a></li>
      <li v-show="showMenu"
          class="login"><a :href="baseUrl + '/#/index'">登录</a></li>

    </ul>
  </div>
</template>
<script>
import { baseUrl, documentUrl } from '@src/config.js';
export default {
  computed: {

  },
  props: {
    bgImgString: String,
    showMenu: false,
  },
  mounted () {
    this.baseUrl = baseUrl;
    this.documentUrl = documentUrl;
  },
  data () {
    return {
      baseUrl: '',
      documentUrl: '',
      bg: '',
    }
  },
  methods: {
    getBgImg () {
      console.log(this.bgImgString)
      return this.bgImgString
    }
  }
}
</script>
<style lang="scss">
.header {
  width: 100%;
  position: relative;
  background: white !important;
  height: auto;
  color: #fff;
  img {
    width: 100%;
    background-size: cover;
  }
  .menu {
    position: absolute;
    left: 3.6rem;
    top: 0;
    z-index: 10;
    font-size: 0.24rem;
    li {
      float: left;
      list-style: none;
      margin-top: 0.43rem;
      margin-right: 1.1rem;
      cursor: pointer;
      a {
        color: #fff;
        text-decoration: none;
      }
      &.index {
        margin-left: 2.8rem;
      }
      &.home:before {
        content: '';
        width: 0.46rem;
        height: 0.4rem;
        display: inline-block;
        vertical-align: middle;
        background: url('../assets/img/logo.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
      }
      &.login:before {
        content: '';
        width: 0.22rem;
        height: 0.24rem;
        display: inline-block;
        vertical-align: middle;
        background: url('../assets/img/denglu.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
        margin-top: -0.05rem;
      }
    }
  }
}
</style>
