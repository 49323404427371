<template>
  <div>
    <div class="top">
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail3-1.png">
        <div class="item">
          <div class="title">健康周/月报</div>
        </div>
      </div>
    </div>
    <div class="t">
      <div class="t-2">

        <div>
          <div class="title-left">
            实用易懂的健康报告
          </div>
          <div class="text-left">
            健康规则层层筛选，AI引擎智能分析<br>
            智能监测体系，提升居民健康检测体验<br>
            阶段性持续监测，最贴合居民实际情况的检测报告<br>
            智能检测提醒，检测后回访<br>
          </div>
          <div class="btn">
            查看报告演示
          </div>
        </div>

        <div class="img-right">
          <img src="../../assets/img/solutionDetail/detail3-2.png">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    };
  },
}
</script>

<style lang="scss"  scoped>
.top {
  display: flex;
  justify-content: space-around;
  margin: 1.2rem 2rem;
  .topItem {
    display: flex;
    justify-content: center;
    img {
      margin: 0 0.2rem;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(89, 89, 89, 1);
      }
      .text {
        font-size: 0.18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
      }
    }
  }
}

.t {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-around;
    width: 12rem;
    flex-direction: row;
    margin-bottom: 1.17rem;
    .title-left {
      text-align: left;
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
    }
    .text-left {
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      text-align: left;
      margin: 0.4rem 0 0.2rem 0;
    }

    .text-c3 {
      margin: 0.25rem 0 0 0;
    }
    .c3 {
      margin: 0.3rem 0 0 0 !important;
      display: flex;
    }
    .img-right {
      margin: 0 0 0 0.85rem;
      background-size: cover;
      img {
        width: 5.7rem;
      }
    }
    .img-left {
      margin: 0 0.85rem 0 0;
      background-size: cover;
    }
    .title {
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
    }
    .btn {
      text-align: left;
      font-size: 0.22rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 198, 174, 1);
    }
  }
}
</style>
