<template>
  <div>
    <div class="top">
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail2-1.png">
        <div class="item">
          <div class="title">健康基本信息</div>
        </div>
      </div>
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail2-2.png">
        <div class="item">
          <div class="title">健康指标数据</div>
        </div>
      </div>
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail2-3.png">
        <div class="item">
          <div class="title">医疗诊断数据</div>
        </div>
      </div>
    </div>
    <div class="t">
      <div class="t-1">
        <div>
          <div class="title-left">
            全面的基本居民信息
          </div>
          <div class="text-left">
            涵盖居民日常生活的主要基本信息<br>
            数据伴随居民，<br>
            持续跟踪居民的信息变化，<br>
            掌握第一手居民资料<br>
            数据增量更新，数据分析形成居民成长轨迹<br>
          </div>
        </div>
        <div class="img-right row-img">
          <img src="../../assets/img/solutionDetail/detail2-4.png">
        </div>

      </div>

      <div class="t-2">
        <div class="img-left row-img">
          <img src="../../assets/img/solutionDetail/detail2-5.png">
        </div>

        <div>
          <div class="title">
            专业、严谨的健康标准
          </div>
          <div class="text">
            专业的医疗团队<br>
            数据分析团队<br>
            制定科学的健康指标标准<br>
            丰富的指标类型<br>
            适用于更多的健康管理场景<br>
          </div>
        </div>
      </div>

      <div class="t-3">
        <div>
          <div class="title-left">
            详细的居民诊疗数据
          </div>
          <div class="img-left c3">
            <img src="../../assets/img/solutionDetail/detail2-6.png">
          </div>
          <div class="text-left text-c3">
            多家医疗机构合作<br>
            诊疗数据平台互通<br>
            居民处方<br>
            病例实时共享<br>
          </div>
        </div>
        <div class="img-right  row-img">
          <img src="../../assets/img/solutionDetail/detail2-7.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    };
  },
}
</script>

<style lang="scss"  scoped>
.top {
  display: flex;
  justify-content: space-around;
  margin: 1.2rem 2rem;
  .topItem {
    display: flex;
    justify-content: center;
    img {
      margin: 0 0.2rem;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(89, 89, 89, 1);
      }
      .text {
        font-size: 0.18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
      }
    }
  }
}

.t {
  display: flex;
  flex-direction: column;
  align-items: center;

  .row-img {
    img {
      width: 5.78rem;
    }
  }

  .t-1 {
    .title {
      text-align: left;
    }
  }

  .t-2 {
    .title {
      text-align: right;
    }
  }

  > div {
    display: flex;
    justify-content: space-around;
    width: 12rem;
    flex-direction: row;
    margin-bottom: 1.17rem;
    .title-left {
      text-align: left;
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
    }
    .text-left {
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      text-align: left;
      margin: 0.4rem 0;
    }

    .text-c3 {
      margin: 0.25rem 0 0 0;
    }
    .c3 {
      margin: 0.3rem 0 0 0 !important;
      display: flex;
      img {
        width: 3.4rem;
      }
    }
    .img-right {
      margin: 0 0 0 0.85rem;
      background-size: cover;
    }
    .img-left {
      margin: 0 0.85rem 0 0;
      background-size: cover;
    }
    .title {
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
    }
    .text {
      text-align: right;
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      margin: 0.4rem 0;
    }
    .num {
      text-align: right;
      font-size: 0.3rem;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: rgba(89, 89, 89, 1);
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      img {
        margin: 0 0.2rem;
      }
    }
  }
}
</style>
