<template>
  <div>
    <div class="top">
      <div class="topItem">
        <div class="img">
          <img src="../../assets/img/solutionDetail/detail1-1.png">
        </div>
        <div class="item">
          <div class="title">设备购买</div>
          <div class="text">助力管理</div>
        </div>
      </div>
      <div class="topItem">
        <img src="../../assets/img/solutionDetail/detail1-2.png">
        <div class="item">
          <div class="title">设备接入</div>
          <div class="text">促进决策</div>
        </div>
      </div>
    </div>
    <div class="t">
      <div class="t-1">
        <div>
          <div class="title">
            主流品牌智能健康设备
          </div>
          <div class="excel">
            <div class="title">
              <div>设备类型</div>
              <div>设备品牌</div>
              <div>连接方式</div>
              <div class="ringt">设备介绍</div>
            </div>
            <div class="item">
              <div>血糖仪</div>
              <div>爱奥乐</div>
              <div>GSM</div>
              <div class="ringt">查看详情</div>
            </div>
            <div class="item">
              <div>血糖仪</div>
              <div>三诺</div>
              <div>蓝牙</div>
              <div class="ringt">查看详情</div>
            </div>
            <div class="item">
              <div>血压计</div>
              <div>脉搏波</div>
              <div>GSM</div>
              <div class="ringt">查看详情</div>
            </div>
            <div class="item">
              <div>血糖仪</div>
              <div>康泰</div>
              <div>蓝牙</div>
              <div class="ringt">查看详情</div>
            </div>
          </div>
        </div>
        <div class="img">
          <img src="../../assets/img/solutionDetail/detail1-3.png">
        </div>
      </div>

      <div class="t-2">
        <div class="img">
          <img src="../../assets/img/solutionDetail/detail1-4.png">
        </div>

        <div>
          <div class="title">
            全生命周期健康数据平台
          </div>
          <div class="text">
            面向不同设备厂商，提供多渠道、多维度的健康数据，健康指标
            基于健康引擎，健康画像核心算法，推荐科学的、个性化的健康服务
            全流程接入跟踪指导，7X24小时技术支持
          </div>
          <div class="num">
            <div>
              027-84683239
            </div>
            <img src='../../assets/img/solutionDetail/dianhua.png'
                 alt="">

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {

    };
  },
}
</script>

<style lang="scss"  scoped>
.top {
  display: flex;
  justify-content: space-around;
  margin: 1.2rem 2rem;
  .topItem {
    display: flex;
    justify-content: center;
    img {
      margin: 0 0.2rem;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      .title {
        font-size: 0.22rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(89, 89, 89, 1);
      }
      .text {
        font-size: 0.18rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
      }
    }
  }
}

.t {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    img {
      width: 5.37rem;
    }
  }
  .t-1 {
    display: flex;
    justify-content: space-between;
    text-align: left;
    .excel {
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      border: 1px solid rgba(238, 238, 238, 1);
      .title {
        background: rgba(238, 238, 238, 1);
        font-size: 0.22rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(89, 89, 89, 1);
        display: flex;
        justify-content: space-around;
        padding: 0.2rem 0;
        div {
          flex: 1;
        }
      }
      .item {
        display: flex;
        justify-content: space-around;
        height: 0.66rem;
        line-height: 0.66rem;
        div {
          flex: 1;
          border: 1px solid rgba(238, 238, 238, 1);
          border-style: none solid none none;
        }
        .ringt {
          border-style: none;
        }
      }
    }
  }
  .t-2 {
    display: flex;
    justify-content: space-between;
    .title {
      text-align: right;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 12rem;
    flex-direction: row;
    margin-bottom: 1.17rem;
    .title {
      font-size: 0.5rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
    }
    .text {
      text-align: right;
      font-size: 0.18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(89, 89, 89, 1);
      margin: 0.4rem 0;
    }
    .num {
      text-align: right;
      font-size: 0.3rem;
      font-family: DIN-Medium, DIN;
      font-weight: 500;
      color: rgba(89, 89, 89, 1);
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      img {
        margin: 0 0.2rem;
      }
    }
  }
}
</style>
